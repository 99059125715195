<template>
  <div class="">
    <!-- <v-container>
      <header class="">
        <h1 class="rs-text--mega mt-12 mb-4 text-center">
          Why Retirement Planning Needs the Social Security Risk Score
        </h1>
        <h2 class="grey--text text--darken-1 text-center">
          Social Security is a Key Part of Your Retirement Plan
        </h2>

        <v-row class="rs-hero__cta">
          <v-col class="col-12 col-md-6 col-lg-7">
            <h3 class="mb-4">
              <strong
                >Did you know that the Social Security Administration has
                disclosed that future reductions to Social Security benefits are
                possible, if not probable?</strong
              >
            </h3>
            <p>
              By 2034, the payroll taxes collected will only be enough to pay
              <strong>77%</strong> of scheduled benefits. Consequently, every
              American faces the risk that their promised Social Security
              benefits may be cut by <strong>23%</strong> in 2034.
            </p>
            <p>
              <strong><em>Are your promised benefits at risk?</em></strong>
            </p>
            <p>
              Assess your likelihood of a reduction to your Social Security
              benefits for <strong class="red--text">FREE</strong>
              today with the Social Security Risk Score!
            </p>

            <p>
              Contact your financial advisor to gain access to the Social
              Security Risk Score assessment.
            </p>

            <v-btn color="primary" depressed href="#contact">
              Get Your Social Security Risk Score
            </v-btn>
          </v-col>

          <v-col class="d-none d-md-block col-12 col-md-6 col-lg-5">
            <v-img src="@/assets/couple-walking-sunset.jpg"></v-img>
          </v-col>
        </v-row>
      </header>

      <section class="rs-section">
        <v-row>
          <v-col class="col-12 col-md-6 order-1 order-md-0 d-flex align-center">
            <v-img
              max-width="350"
              src="@/assets/coins-graphic.png"
              class="mx-auto"
            ></v-img>
          </v-col>
          <v-col class="col-12 col-md-6 order-0 order-md-1">
            <h3 class="mb-4 text-md-h4 font-weight-bold">
              Why You Should Get Your<br />
              <span class="primary--text">Social Security Risk Score</span>
            </h3>
            <p>
              Social Security is America's primary retirement plan and the
              cornerstone for most retirees. For many years, Social Security has
              struggled to manage the amount of revenue collected through
              payroll taxes against the amount of benefits paid out.
            </p>
            <p>
              The demographic and economic changes of the Baby Boomers' mass
              exodus from the workforce, coupled with the historically low
              interest rate environment, has continued to increase the financial
              strain on the Social Security system. To prevent everyone from
              seeing a reduction, Congress may change the rules so that only
              certain groups are targeted.
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-12 col-md-6">
            <v-alert outlined color="info"
              ><strong>
                A reduction in Social Security benefits could derail your
                retirement plans and increase your risk of outliving your
                savings, making it much harder to pay for unexpected health
                issues or for leaving a legacy. Everyone has a different level
                of risk. The Social Security Risk Score can calculate your
                likelihood of a reduction and spot potential impacts on your
                retirement plan.</strong
              >
            </v-alert>
          </v-col>
          <v-col class="col-12 col-md-6 d-flex flex-column align-center">
            <p>
              <strong
                ><em
                  >Don't be surprised tomorrow by what you can plan for
                  today.</em
                ></strong
              >
            </p>

            <v-btn color="primary" depressed href="#contact">
              Get Your Social Security Risk Score
            </v-btn>
          </v-col>
        </v-row>
      </section>

      <section class="rs-section mb-0">
        <v-row class="mb-4">
          <v-col class="col-12 col-md-8 offset-md-2 text-center"
            ><h3 class="mb-4 text-lg-h4 font-weight-bold">
              Social Security is at Risk
            </h3>
            <p>
              If Congress waits until the last minute to fix Social Security,
              then they will be forced to either cut benefits by 25%, raise
              taxes by 33%, or most likely, a combination of both.
            </p>
          </v-col></v-row
        >
        <v-card
          color="primary"
          elevation="12"
          tile
          dark
          max-width="1200"
          class="mx-auto"
        >
          <v-card-title class="justify-center"
            ><strong>How You Could Be Affected</strong></v-card-title
          >
          <v-divider class="mb-2"></v-divider>
          <v-card-text class="white--text">
            <v-row>
              <v-col class="col-12 col-md-4">
                <h3 class="mb-4">
                  <v-icon x-large class="mr-4">mdi-cash</v-icon>Means-Test
                  Benefits
                </h3>
                <p>
                  10 Million+ Retirees paying higher Part B Premiums by 2030.
                  Widespread implementation during the Pandemic.
                </p>
              </v-col>
              <v-col class="col-12 col-md-4">
                <h3 class="mb-4">
                  <v-icon x-large class="mr-4">mdi-human-cane</v-icon>Raise Full
                  Retirement Age
                </h3>
                <p>
                  The last time the Trust Fund faced insolvency, Congress chose
                  to increase the Full Retirement Age, reducing benefits at
                  earlier ages.
                </p>
              </v-col>
              <v-col class="col-12 col-md-4">
                <h3 class="mb-4">
                  <v-icon x-large class="mr-4">mdi-trending-down</v-icon>Reduce
                  Overall Benefits
                </h3>
                <p>
                  Younger generations accept higher tax rates combined with
                  partial benefit reductions for older generations.
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </section>
      <section class="rs-section">
        <v-row class="mb-4">
          <v-col class="col-12 col-md-8 offset-md-2 text-center"
            ><h3 class="mb-4 text-lg-h4 font-weight-bold">
              Social Security Risk Score
            </h3>
            <p>
              The Social Security Risk Score is a personalized assessment that
              calculates the risk of your likelihood to receive a benefit
              reduction.
            </p>
          </v-col></v-row
        >
      </section>
    </v-container> -->

    <!-- <section class="pb-12">
      <v-container>
        <v-row>
          <v-col class="col-12 col-lg-10 offset-lg-1">
            <v-row>
              <v-col
                v-for="c in categories"
                :key="c.title"
                class="col-6 col-lg-3"
              >
                <div class="d-flex flex-column align-center mb-4">
                  <v-avatar size="80" class="mr-4">
                    <v-img :src="require('@/assets/' + c.image)"></v-img>
                  </v-avatar>
                  <span class="text-lg-h6 text-center">{{ c.title }}</span>
                </div>
              </v-col>
            </v-row></v-col
          >
        </v-row>

        <div id="contact"></div>
      </v-container>
    </section> -->
    <!-- <div class="rs-random-bar" :class="getRiskClass"></div> -->
    <section class="py-10 secondary white--text">
      <v-container>
        <v-row class="text-center text-lg-left">
          <v-col class="col-12 col-lg-6">
            <h2 class="mb-8">What's Your Score?</h2>
            <h3 class="mb-8">
              Contact your financial advisor for access, or fill out the form
              below to request additional information.
            </h3>
            <!-- <v-divider dark class="mb-6"></v-divider> -->
            <ContactFormGeneric />
          </v-col>

          <v-col class="col-12 col-lg-6 mt-4 mt-lg-0">
            <v-row class="flex-column flex-md-row flex-lg-column">
              <v-col class="col-12 col-md-6 col-lg-12">
                <v-img
                  contain
                  max-width="400"
                  src="@/assets/rs-report-4part-graphic.png"
                  class="mx-auto px-2"
                ></v-img>
              </v-col>

              <v-col class="col-12 col-md-6 col-lg-12">
                <div
                  class="mt-4 d-flex flex-column align-center font-weight-bold"
                >
                  <v-list flat dark color="transparent">
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-check</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          >Determine your level of risk</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-check</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          >Keep an eye on your retirement</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-check</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          >Get help to reduce risk</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import ContactFormGeneric from "@/components/subscribeForms/ContactFormGeneric.vue";

export default {
  components: {
    ContactFormGeneric,
  },
  data() {
    return {
      randomScore: 87,
      categories: [
        {
          title: "Demographics",
          image: "score_demographics.svg",
        },
        {
          title: "Finances",
          image: "score_finances.svg",
        },
        {
          title: "Wellness",
          image: "score_wellness.svg",
        },
        {
          title: "Retirement Planning",
          image: "score_retirement_plan.svg",
        },
      ],
    };
  },
  computed: {
    getRiskClass() {
      if (this.randomScore <= 45) {
        return "is-risk--low";
      } else if (this.randomScore > 45 && this.randomScore < 75) {
        return "is-risk--medium";
      } else if (this.randomScore >= 75) {
        return "is-risk--high";
      } else {
        return "";
      }
    },
  },
  created() {},
  mounted() {
    setInterval(() => {
      this.randomScore = this.randomInterval(32, 85, 1);
    }, 2000);
  },
  methods: {
    randomInterval(min, max, step) {
      const numberOfSteps = Math.floor((max - min) / step) + 1;
      const randomStep = Math.floor(Math.random() * numberOfSteps);
      return min + randomStep * step;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item__title {
  font-size: 0.75rem;
  @media only screen and (min-width: 960px) {
    font-size: 1rem;
  }
  @media only screen and (min-width: 1264px) {
    font-size: 1.25rem;
  }
}
.rs-hero {
  &__cta {
    margin-top: 5rem;
  }
}

.rs-section {
  margin-top: 10%;

  h2 {
    max-width: 800px;
    margin: 0 auto 1rem;
    font-weight: 400;
    font-size: 2.125rem;
    line-height: 1.3;
  }
}

.rs-text {
  &--mega {
    font-size: 2rem;
    line-height: 1.3;
  }
}

.rs-rotate {
  &__parent {
    perspective: 1000px;
  }
  &__child {
    transform: rotateY(355deg);
  }
}

.rs-random-gauge {
  max-width: 400px;
  margin: 0 auto;
}

.rs-random-bar {
  height: 8px;
  transition: all 0.15s linear;

  &.is-risk {
    &--high {
      box-shadow: 0 -8px 50px 20px rgba(#c1281b, 0.2);
      background-color: #c1281b;
    }
    &--medium {
      box-shadow: 0 -8px 50px 10px rgba(#f39c12, 0.2);
      background-color: #f39c12;
    }
    &--low {
      box-shadow: 0 -8px 50px 20px rgba(#64b571, 0.2);
      background-color: #64b571;
    }
  }
}
</style>
