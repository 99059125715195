<template>
  <div>
    <div v-if="showMessage">
      <v-alert :type="messageType" outlined v-html="subscribeMessage">
      </v-alert>
    </div>
    <mailchimp-subscribe
      v-if="!isSubscribed"
      :url="config.MAILCHIMP_POST_URL"
      :user-id="config.MAILCHIMP_USER_ID"
      :list-id="config.MAILCHIMP_AUDIENCE_ID"
      @error="onSubscribeError"
      @success="onSubscribeSuccess"
    >
      <template v-slot="{ subscribe, setField, error, success, loading }">
        <div>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="validate"
            lazy-validation
          >
            <v-text-field
              v-model="contact.firstName"
              @input="setField('FNAME', contact.firstName)"
              :rules="requiredNameRules"
              label="First Name"
              dark
              outlined
              required
            ></v-text-field>
            <v-text-field
              v-model="contact.lastName"
              @input="setField('LNAME', contact.lastName)"
              :rules="requiredNameRules"
              label="Last Name"
              dark
              outlined
              required
            ></v-text-field>
            <v-text-field
              v-model="contact.email"
              @input="setField('EMAIL', contact.email)"
              :rules="emailRules"
              label="E-mail"
              dark
              outlined
              required
            ></v-text-field>
            <v-text-field
              v-model="contact.zip"
              @input="setField('ZIPCODE', contact.zip)"
              :rules="zipCodeRules"
              label="Zip Code"
              dark
              outlined
              required
            ></v-text-field>
            <p>Enter your Financial Advisor's information if you have one:</p>
            <v-text-field
              v-model="contact.advisorFirstName"
              @input="setField('AFNAME', contact.advisorFirstName)"
              :rules="nameRules"
              label="Advisor First Name"
              dark
              outlined
            ></v-text-field>
            <v-text-field
              v-model="contact.advisorLastName"
              @input="setField('ALNAME', contact.advisorLastName)"
              :rules="nameRules"
              label="Advisor Last Name"
              dark
              outlined
            ></v-text-field>
            <v-text-field
              v-model="contact.advisorEmail"
              @input="setField('AEMAIL', contact.advisorEmail)"
              :rules="advisorEmailRules"
              label="Advisor E-mail"
              dark
              outlined
            ></v-text-field>

            <v-checkbox
              v-model="contact.isAdvisor"
              @change="setField('ISADVISOR', contact.isAdvisor)"
              label="I am a financial advisor"
              dark
            ></v-checkbox>

            <v-btn
              :disabled="!valid"
              x-large
              dark
              color="primary"
              class="mr-4"
              type="submit"
            >
              Submit
            </v-btn>

            <div v-if="loading">Sending your request...</div>

            <!-- <v-btn color="error" class="mr-4" @click="reset"> Reset Form </v-btn> -->

            <!-- <v-btn color="warning" @click="resetValidation">
            Reset Validation
          </v-btn> -->
          </v-form>
        </div>
      </template>
    </mailchimp-subscribe>
  </div>
</template>

<script>
import config from "@/config";
import MailchimpSubscribe from "@/mailchimpSubscribe";

export default {
  components: {
    MailchimpSubscribe,
  },
  data() {
    return {
      config: config,
      valid: false,
      isSubscribed: false,
      showMessage: "",
      messageType: "",
      subscribeMessage: "",
      contact: {
        firstName: "",
        lastName: "",
        email: "",
        zip: "",
        advisorFirstName: "",
        advisorLastName: "",
        advisorEmail: "",
        isAdvisor: false,
      },
      nameRules: [
        (v) => !v || v.length >= 3 || "Name must be at least 3 characters",
      ],
      requiredNameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length >= 3 || "Name must be at least 3 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      advisorEmailRules: [
        (v) => !v || /.+@.+/.test(v) || "E-mail must be valid",
      ],
      zipCodeRules: [
        (v) => !!v || "Zip Code is required",
        (v) =>
          /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(v) ||
          "Please enter a valid zip code.",
      ],
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();

      if (this.valid) {
        this.$emit("doSubscribe");
      }
    },
    onSubscribeError(error) {
      // handle error
      console.log("SUBSCRIBE ERROR", error);
      this.subscribeMessage = `${error}`;
      this.showMessage = true;
      this.messageType = "error";
    },
    onSubscribeSuccess() {
      // handle subscribe success
      this.isSubscribed = true;
      this.subscribeMessage =
        "Request received! We will contact you soon with additional information.";
      this.showMessage = true;
      this.messageType = "success";

      this.$vuetify.goTo(0);
    },
  },
};
</script>

<style lang="scss" scoped></style>
